// API URLs
const urls = {
  // base url
  baseURL: {
    dev: "https://dev.sunnivoice.com/",
    live: "https://api.sunnivoice.com/",
  },

  // auth apis
  login: "api/auth/v1/admin/login",
  sendOTP: "api/auth/v1/admin/send_otp",
  verifyOTP: "api/auth/v1/admin/verify_otp",
  resetPassword: "api/auth/v1/admin/reset_password",
  refreshToken: "api/auth/v1/admin/refresh_token",
  logout: "api/auth/v1/admin/logout",

  // main apis
  dashboard: "api/magazine/v1/admin/dashboard",
  magazines: `api/magazine/v1/admin/magazine_list`,
  articles: "api/magazine/v1/admin/article_list",
  authors: "api/magazine/v1/admin/author_list",
  deleteAuthor: "api/magazine/v1/admin/delete_author/",
  categories: "/api/magazine/v1/admin/article_category_list?page=1&limit=10",
  languages: "api/magazine/v1/admin/language_list",
  advertisements: "api/magazine/v1/admin/advertisement_list",
  feedbacks: "api/magazine/v1/admin/feedback_list",
  mediaUpload: "api/magazine/v1/admin/media_upload",
  categoriesDropdown: "api/magazine/v1/admin/article_category_drop_down",
  createArticle: "api/magazine/v1/admin/create_article/",
  createCategory: "api/magazine/v1/admin/create_article_category",
  deleteCategory: "api/magazine/v1/admin/delete_article_category/",
  editArticle: "api/magazine/v1/admin/edit_article/",
  articleDetails: "api/magazine/v1/admin/article_details/",
  createAuthor: "api/magazine/v1/admin/create_author",
  createMagazine: "api/magazine/v1/admin/create_magazine",
  getProductSet: "api/magazine/v1/admin/product_set_list",
  magazineDetails: "api/magazine/v1/admin/magazine_details/",
  subscriptionList: "api/magazine/v1/admin/subscription_plan_list",
  deleteMagazine: "api/magazine/v1/admin/delete_magazine/",
  deleteArticle: "api/magazine/v1/admin/delete_article/",
  editMagazine: "api/magazine/v1/admin/edit_magazine/",
  addUser: "api/magazine/v1/admin/add_users",
  editAuthor: "api/magazine/v1/admin/edit_author",
  publishMagazine: "api/magazine/v1/admin/publish_magazine/",
  users: "api/magazine/v1/admin/subscribed_users_list",
  plans: "api/magazine/v1/admin/subscription_plan_list",
  createAdvertisement: "api/magazine/v1/admin/create_advertisement",
  editAdvertisement: "api/magazine/v1/admin/edit_advertisement/",
  deleteAdvertisement: "api/magazine/v1/admin/delete_advertisement/",
  excelUserUpload: "api/magazine/v1/admin/excel_user_upload",
};

export default urls;
