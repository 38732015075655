import "remixicon/fonts/remixicon.css";

export { default as logo } from "./images/logo.svg";
export { default as tick } from "./images/tick.svg";
export { default as magazine1 } from "./images/magazine1.svg";
export { default as article1 } from "./images/article1.svg";
export { default as author1 } from "./images/author1.svg";
export { default as coverPlaceholder } from "./images/cover-placeholder.svg";
export { default as deleteBlack } from "./images/delete-black.svg";
export { default as profilePlaceholder } from "./images/profile-placeholder.svg";
export { default as moveDown } from "./images/moveDown.svg";
export { default as moveUp } from "./images/moveUp.svg";
export { default as duplicate } from "./images/duplicate.svg";
export { default as dotMenu } from "./images/dotMenu.svg";
export { default as audio } from "./images/audio.svg";
export { default as audioWave } from "./images/audio-wave.svg";
export { default as image1 } from "./images/image1.svg";
export { default as video1 } from "./images/video1.svg";
export { default as ad1 } from "./images/ad1.svg";
export { default as imagePlaceholder } from "./images/image-placeholder.svg";
export { default as uploadUsersSample } from "./upload-users-sample.xlsx";

export function EyeIcon(props) {
  const { color } = props;
  return (
    <div style={{ color: color }}>
      <i className="ri-eye-line"></i>
    </div>
  );
}

export function EyeOffIcon(props) {
  const { color } = props;
  return (
    <div style={{ color: color }}>
      <i className="ri-eye-off-line"></i>
    </div>
  );
}

export function CircleIcon(props) {
  const { color, size } = props;
  return (
    <div style={{ color: color, fontSize: size }}>
      <i style={{ fontSize: size }} className="ri-circle-fill"></i>
    </div>
  );
}

// dynamic side menu icons
export function SideMenuIcon(props) {
  const { color, size, name } = props;
  return (
    <div style={{ color: color, fontSize: size }}>
      <i style={{ fontSize: size }} className={name}></i>
    </div>
  );
}
